import React from "react";
import {
  ExamSelectionComponent,
  ExamExpectationComponent
} from "tuteria-frontend-components/src/pages/ExamRequestPages";
import groups from "./groups.json";

export class ExamSelectionPage extends React.Component {
  constructor(props) {
    super(props);
    let { request_details = {} } = this.props.state;
    request_details = { ...request_details, exam: this.props.exam };
    this.state = {
      student_no: 1,
      ...request_details
    };
  }
  componentDidMount() {
    this.props.updateState({ currentStep: 1 });
  }
  // componentDidMount() {
  //   let { navigate } = this.props;
  //   if (this.state.classes.length === 0) {
  //     navigate("/exam-preference");
  //   }
  // this.props.updateState({ currentStep: 1 });
  // }
  completeStep = (data = {}) => {
    let {
      updateState,
      navigate,
      state: { request_details }
    } = this.props;
    updateState(
      {
        request_details: { ...request_details, ...data }
      },
      true,
      () => {
        navigate("/exam-preference");
      }
    );
  };

  render() {
    return (
      <ExamSelectionComponent
        onSubmit={this.completeStep}
        exam={this.props.exam}
        // backAction={this.goBack}
        // showBackButton={true}
        data={this.state}
        groups={groups}
      />
    );
  }
}

export class ExamExpectationPage extends React.Component {
  constructor(props) {
    super(props);
    let { request_details = {} } = this.props.state;
    request_details = { ...request_details, exam: this.props.exam };
    this.state = {
      selections: [],
      ...request_details
    };
  }
  componentDidMount() {
    this.props.updateState({ currentStep: 1 });
    let { exam_type, purpose, student_no } = this.state;
    if (![exam_type, purpose, student_no].every(Boolean)) {
      this.props.navigate("/about-exam");
    }
  }
  onSubmit = data => {
    let {
      navigate,
      updateState,
      state: { request_details }
    } = this.props;
    updateState({ request_details: { ...request_details, ...data } });
    navigate("/location");
  };
  goBack = () => {
    this.props.navigate("/about-exam");
  };
  render() {
    return (
      <ExamExpectationComponent
        groups={groups}
        data={this.state}
        onSubmit={this.onSubmit}
        showBackButton
        backAction={this.goBack}
      />
    );
  }
}
