import React from "react";
import { PageContainer } from "tuteria-frontend-components/src/pages/ClientRequestPages/ClientPageWrapper";
import { Switch, Route, Redirect } from "react-router-dom";
import { injectGlobal } from "styled-components";
import { ExamSelectionPage, ExamExpectationPage } from "./AboutExamPage";
import { ContactInfoPage, LocationPage } from "./ContactInfoPage";
import { LessonSchedulePage } from "./LessonSchedulePage";
import ClientThankYouPage from "./ClientThankYouPage";
import { color } from "tuteria-frontend-components/src/siteStyle";

let tabs = [
  "About Exam",
  // "Exam Preferences",
  "Contact Information",
  "Lesson Schedule",
  "Complete Request"
];

injectGlobal`
body {
  background: ${color.white}!important;
}
#root {
  overflow-x: hidden;
}
`;
export class ExamFlow extends React.Component {
  navigate = path => {
    let { basePath = "" } = this.props;
    this.props.history.push(`${basePath}${path}`);
  };
  render() {
    let { currentStep } = this.props.state.state;
    let { basePath = "" } = this.props;
    return (
      <PageContainer steps={tabs} current={currentStep}>
        <Switch>
          <Route
            path={`${basePath}/about-exam`}
            render={props => (
              <ExamSelectionPage
                {...props}
                exam={this.props.exam}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <Route
            path={`${basePath}/exam-preference`}
            render={props => (
              <ExamExpectationPage
                {...props}
                exam={this.props.exam}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <Route
            path={`${basePath}/location`}
            render={props => (
              <LocationPage
                {...props}
                exam={this.props.exam}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <Route
            path={`${basePath}/contact-information`}
            render={props => (
              <ContactInfoPage
                {...props}
                exam={this.props.exam}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <Route
            path={`${basePath}/lesson-schedule/:slug`}
            render={props => {
              return (
                <LessonSchedulePage
                  {...props}
                  {...this.props.state}
                  exam={this.props.exam}
                  navigate={this.navigate}
                />
              );
            }}
          />

          <Route
            path={`${basePath}/request-completed/:slug`}
            render={props => (
              <ClientThankYouPage
                {...props}
                {...this.props.state}
                exam={this.props.exam}
                navigate={this.navigate}
              />
            )}
          />
          <Redirect from={`${basePath}/`} to={`${basePath}/exam-preference`} />
        </Switch>
      </PageContainer>
    );
  }
}

export default ExamFlow;
